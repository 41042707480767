exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blogDetails.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-card-product-js": () => import("./../../../src/pages/cardProduct.js" /* webpackChunkName: "component---src-pages-card-product-js" */),
  "component---src-pages-catalog-details-js": () => import("./../../../src/pages/catalogDetails.js" /* webpackChunkName: "component---src-pages-catalog-details-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-catalog-table-js": () => import("./../../../src/pages/catalogTable.js" /* webpackChunkName: "component---src-pages-catalog-table-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plate-details-js": () => import("./../../../src/pages/plateDetails.js" /* webpackChunkName: "component---src-pages-plate-details-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

